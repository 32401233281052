
export default {
  name: 'IntegrationsIndex',
  data() {
    return {
      listBranch: [
        {
          img: '/logo/lg-shopbase.png',
          webp: '/logo/lg-shopbase.webp',
        },
        {
          img: '/logo/lg-pingpong.png',
          webp: '/logo/lg-pingpong.webp',
        },
        {
          img: '/logo/lg-senprints.png',
          webp: '/logo/lg-senprints.webp',
        },
        {
          img: '/logo/lg-moteete.png',
          webp: '/logo/lg-moteete.webp',
        },
        {
          img: '/logo/lg-accesstrade.png',
          webp: '/logo/lg-accesstrade.webp',
        },
        {
          img: '/logo/lg-payoneer.png',
          webp: '/logo/lg-payoneer.webp',
        },
        {
          img: '/logo/lg-gearbubble.png',
          webp: '/logo/lg-gearbubble.webp',
        },
        {
          img: '/logo/lg-airwallex.png',
          webp: '/logo/lg-airwallex.webp',
        },
        {
          img: '/logo/lg-chip.png',
          webp: '/logo/lg-chip.webp',
        },
        {
          img: '/logo/lg-shineon.png',
          webp: '/logo/lg-shineon.webp',
        },
        {
          img: '/logo/lg-printway.png',
          webp: '/logo/lg-printway.webp',
        },
        {
          img: '/logo/lg-teeallover.png',
          webp: '/logo/lg-teeallover.webp',
        },
        {
          img: '/logo/lg-burger-prints.png',
          webp: '/logo/lg-burger-prints.webp',
        },
        {
          img: '/logo/lg-cj.png',
          webp: '/logo/lg-cj.webp',
        },
      ],
    }
  },
}
